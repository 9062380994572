import type { InMemoryCacheConfig } from "@apollo/client";
import { offsetLimitPagination } from "@apollo/client/utilities";
import type { Originator_Metadata } from "@greenline/hasura/bin/graphql.types";

import type {
  Application_Eligible_Income_Category,
  Application_Invite,
  Comment_Thread,
  End_User,
  Financial_Institution,
  Team_Member_Invite,
  Transaction_Category_Override,
  Underwriter_Application_Income_Category,
  Underwriter_Metadata,
  User,
} from "@/gql/graphql";

export const apolloCacheConfig = {
  // https://www.apollographql.com/docs/react/caching/cache-configuration/#customizing-cache-ids
  typePolicies: {
    ["team_member_invite" satisfies Team_Member_Invite["__typename"]]: {
      keyFields: ["invite_id"] satisfies Array<keyof Team_Member_Invite>,
    },
    ["comment_thread" satisfies Comment_Thread["__typename"]]: {
      fields: {
        // Always replace the comments list - don't merge
        comments: {
          merge(_, incoming) {
            return incoming;
          },
        },
      },
    },
    ["originator_metadata" satisfies Originator_Metadata["__typename"]]: {
      keyFields: ["organization_id"] satisfies Array<keyof Originator_Metadata>,
    },
    ["underwriter_metadata" satisfies Underwriter_Metadata["__typename"]]: {
      keyFields: ["organization_id"] satisfies Array<
        keyof Underwriter_Metadata
      >,
    },
    ["user" satisfies User["__typename"]]: {
      keyFields: ["user_id"] satisfies Array<keyof User>,
    },
    ["end_user" satisfies End_User["__typename"]]: {
      keyFields: ["user_id"] satisfies Array<keyof End_User>,
    },
    ["application_invite" satisfies Application_Invite["__typename"]]: {
      keyFields: ["invite_id"] satisfies Array<keyof Application_Invite>,
    },
    ["financial_institution" satisfies Financial_Institution["__typename"]]: {
      keyFields: ["institution_id"] satisfies Array<
        keyof Financial_Institution
      >,
    },
    ["transaction_category_override" satisfies Transaction_Category_Override["__typename"]]:
      {
        // Both the application ID and the transaction category are needed to uniquely identify an application_eligible_income_category
        keyFields: ["organization_id", "transaction_id"] satisfies Array<
          keyof Transaction_Category_Override
        >,
      },
    ["application_eligible_income_category" satisfies Application_Eligible_Income_Category["__typename"]]:
      {
        // Both the application ID and the transaction category are needed to uniquely identify an application_eligible_income_category
        keyFields: ["application_id", "transaction_category"] satisfies Array<
          keyof Application_Eligible_Income_Category
        >,
      },
    ["underwriter_application_income_category" satisfies Underwriter_Application_Income_Category["__typename"]]:
      {
        // Both the underwriter_application_id and the transaction category are needed to uniquely identify an underwriter_application_income_category
        keyFields: [
          "underwriter_application_id",
          "transaction_category",
        ] satisfies Array<keyof Underwriter_Application_Income_Category>,
      },

    Query: {
      fields: {
        // This helper merges paginated results in the cache unless query args `where` or `order_by` change
        // https://www.apollographql.com/docs/react/pagination/offset-based/#the-offsetlimitpagination-helper
        transaction: offsetLimitPagination(["where", "order_by"]),
        // Always replace the list for these fields
        comment_thread: {
          merge(_, incoming) {
            return incoming;
          },
        },
      },
    },
  },
} as const satisfies InMemoryCacheConfig;
