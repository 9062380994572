const isClient = typeof window !== "undefined";
const isTest = process.env.NODE_ENV === "test";

/**
 * Derives the hasura-related environment variables and verifies that they are valid.
 */
export const getHasuraEnvVars = () => {
  let hasuraGraphqlDomain = process.env.NEXT_PUBLIC_HASURA_GRAPHQL_DOMAIN;
  let hasuraAdminSecret = process.env.HASURA_GRAPHQL_ADMIN_SECRET;

  try {
    if (!hasuraGraphqlDomain) {
      throw new Error(
        "Unable to perform graphql-codegen. Environment variable 'NEXT_PUBLIC_HASURA_GRAPHQL_DOMAIN' is undefined."
      );
    } else {
      try {
        new URL(hasuraGraphqlDomain);
      } catch (error) {
        throw new Error(
          "Unable to perform graphql-codegen. Environment variable 'NEXT_PUBLIC_HASURA_GRAPHQL_DOMAIN' is not a valid domain string."
        );
      }
    }
    if (!isClient && !hasuraAdminSecret) {
      throw new Error(
        "Unable to perform graphql-codegen. Environment variable 'HASURA_GRAPHQL_ADMIN_SECRET' is undefined."
      );
    }
  } catch (e) {
    if (isTest) {
      hasuraGraphqlDomain = "http://localhost:8080";
      hasuraAdminSecret = isClient ? undefined : "testHasuraSecret";
    } else {
      throw e;
    }
  }

  const hasuraAPIUrl = new URL("/v1/graphql", hasuraGraphqlDomain);
  const hasuraRelayAPIUrl = new URL("/v1beta1/relay", hasuraGraphqlDomain);
  const hasuraSubscriptionUrl = new URL("/v1/graphql", hasuraGraphqlDomain);

  if (hasuraAPIUrl.protocol === "https:") {
    hasuraSubscriptionUrl.protocol = "wss:";
  } else {
    hasuraSubscriptionUrl.protocol = "ws:";
  }

  return {
    // The default hasura graphql API endpoint
    hasuraAPIUrl: hasuraAPIUrl.href,
    // The hasura relay graphql API endpoint
    hasuraRelayAPIUrl: hasuraRelayAPIUrl.href,
    // The hasura websocket subscription API endpoint
    hasuraSubscriptionUrl: hasuraSubscriptionUrl.href,
    // The server-only admin secret. Will be undefined on the client.
    adminSecret: hasuraAdminSecret ?? undefined,
  };
};
