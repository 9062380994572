import { useState } from "react";

/**
 * Track the previous value of a variable.
 * This can be particularly handy in scenarios where it is necessary
 * to compare the current value with the previous one,
 * such as triggering actions or rendering based on changes.
 *
 * @param value: The new value to track and return the previous of.
 * @returns The previous value of the provided value.
 */
export function usePrevious<T>(value: T): T | undefined {
  const [current, setCurrent] = useState<T>(value);
  const [previous, setPrevious] = useState<T | undefined>(undefined);

  if (value !== current) {
    setPrevious(current);
    setCurrent(value);
  }

  return previous;
}
