"use client";

import { VercelToolbar } from "@vercel/toolbar/next";

import { useIsGreenlineStaff } from "@/components/organisms/StaffToolbar/useIsGreenlineStaff";

/**
 * @see https://vercel.com/docs/workflow-collaboration/vercel-toolbar/in-production-and-localhost/add-to-production
 */
export function StaffToolbar() {
  const isEmployee = useIsGreenlineStaff();
  return isEmployee ? <VercelToolbar /> : null;
}
