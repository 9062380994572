"use client";

import { useUser } from "@clerk/nextjs";
import { H } from "@highlight-run/next/client";
import { useEffect } from "react";

/**
 * Tag the Highlight.io sessions so that they can be filtered by user attributes.
 */
export const UserIdentifier = () => {
  const { isSignedIn, isLoaded: isClerkLoaded, user } = useUser();

  useEffect(() => {
    if (isClerkLoaded && isSignedIn) {
      H.identify(user.id);
    }
  });

  return null;
};
