import { onError } from "@apollo/client/link/error";
import { getMainDefinition } from "@apollo/client/utilities";
import { omitBy, pickBy } from "lodash";

/**
 * An onError link that checks for graphQLErrors or a networkError in the server's response.
 * It logs the details of whichever error(s) it finds.
 */
export const errorLoggerLink = onError(
  ({
    /**
     * These are errors related to the server-side execution of a GraphQL operation. They include:
     * - Syntax errors (e.g., a query was malformed)
     * - Validation errors (e.g., a query included a schema field that doesn't exist)
     * - Resolver errors (e.g., an error occurred while attempting to populate a query field)
     *
     * If a syntax error or validation error occurs, your server doesn't execute the operation
     * at all because it's invalid.
     */
    graphQLErrors,
    /**
     * These are errors encountered while attempting to communicate with your GraphQL server, usually
     * resulting in a 4xx or 5xx response status code (and no data).
     * A networkError can contain additional fields, such as a GraphQL object in the case of a failing HTTP status code.
     * In this situation, graphQLErrors is an alias for networkError.result.errors if the property exists.
     */
    networkError,
    operation,
  }) => {
    const operationName =
      operation.operationName || "Unnamed GraphQL operation";
    const { headers, response } = operation.getContext() || {};
    const operationHeaders = omitBy(
      {
        ...headers,
        Authorization:
          headers?.Authorization?.replace(/^(\w+ )?.+$/, "$1****") || undefined,
        "x-hasura-invite-id":
          headers?.["x-hasura-invite-id"]?.replace(/^(.{4})?.+$/, "$1****") ||
          undefined,
        "x-hasura-admin-secret": undefined, // never log the admin secret
      },
      (x) => !x
    );

    // Get the hasura request ID from the response so we can cross-reference with the hasura logs
    const requestId = (response as Response | undefined)?.headers.get(
      "x-request-id"
    );

    const definition = getMainDefinition(operation.query);
    const isSubscription =
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription";

    if (networkError) {
      console.error(
        `[Network error]: operation "${operationName}"`,
        pickBy(
          {
            operationName,
            error: networkError,
            isSubscription,
            statusCode:
              "statusCode" in networkError
                ? networkError.statusCode
                : undefined,
            headers: JSON.stringify(operationHeaders),
            requestId,
            variables:
              process.env.NODE_ENV === "development"
                ? JSON.stringify(operation.variables)
                : undefined,
          },
          (x) => !!x
        )
      );
    }

    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path, extensions }) => {
        // Logs are automatically recorded by the highlight SDK.
        // Arguments passed as a dictionary as the second parameter will be interpreted as
        // structured key-value pairs that logs can be easily searched by.
        console.error(
          `[GraphQL error]: operation "${operationName}"`,
          pickBy(
            {
              operationName,
              error: message,
              locations,
              isSubscription,
              path: path || extensions?.path,
              /** The error code is one of ApolloServerErrorCode */
              errorCode: extensions?.code,
              headers: JSON.stringify(operationHeaders),
              requestId,
              variables:
                process.env.NODE_ENV === "development"
                  ? JSON.stringify(operation.variables)
                  : undefined,
            },
            (x) => !!x
          )
        );
      });
    }
  }
);
