import type { ClientOptions, EventOpenedListener } from "graphql-ws";
import { createClient } from "graphql-ws";

/**
 * Copied from https://the-guild.dev/graphql/ws/recipes#client-usage-with-graceful-restart
 */
export function createRestartableWsClient(options: ClientOptions) {
  let restartRequested = false;
  let restart = () => {
    restartRequested = true;
  };

  const onOpened = (socket: WebSocket) => {
    options.on?.opened?.(socket);

    restart = () => {
      if (socket.readyState === WebSocket.OPEN) {
        // if the socket is still open for the restart, do the restart
        socket.close(4205, "Client Restart");
      } else {
        // otherwise the socket might've closed, indicate that you want
        // a restart on the next opened event
        restartRequested = true;
      }
    };

    // just in case you were eager to restart
    if (restartRequested) {
      restartRequested = false;
      restart();
    }
  };

  const client = createClient({
    ...options,
    on: {
      ...options.on,
      opened: onOpened as EventOpenedListener,
    },
  });

  return {
    ...client,
    restart: () => restart(),
  };
}
