import type { GetToken } from "@clerk/types";
import type { HasuraHeaders } from "@greenline/hasura/bin/HasuraHeaders";
import type { HasuraUserRole } from "@greenline/hasura/bin/HasuraUserRole";
import { pickBy } from "lodash";

export type HasuraHeaderArgs = {
  /**
   * The signed token including the session claims that Hasura will validate against.
   * If not provided, Hasura automatically assumes the HasuraUserRole.LoggedOut role.
   */
  getToken?: GetToken;
  /**
   * The session's active organization ID.
   * If set, the Clerk "hasura-active-org-role" JWT template will be used.
   * Else, the "hasura-personal" template will be used.
   */
  orgId?: string | undefined | null;
  inviteId?: string | undefined | null;
  /**
   * If provided, it will override the role in the JWT, but it will be rejected if an
   * Authorization token is provided and the role is not included in the JWT's x-hasura-allowed-roles list.
   */
  hasuraRoleOverride?: HasuraUserRole;
};

/**
 * Constructs the Headers expected by Hasura.
 */
export async function getHasuraHeaders({
  getToken,
  orgId,
  inviteId,
  hasuraRoleOverride,
}: HasuraHeaderArgs): Promise<{
  Authorization?: string | undefined;
  "x-hasura-role"?: HasuraUserRole | undefined;
  "x-hasura-invite-id"?: string | undefined;
  "x-hasura-allowed-application-ids"?: string | undefined;
}> {
  // The token will be null if no one is logged in.
  const token = await getToken?.({
    template: orgId ? "hasura-active-org-role" : "hasura-personal",
  });

  const headers = {
    Authorization: token ? `Bearer ${token}` : undefined,
    "x-hasura-invite-id": inviteId || undefined,
    "x-hasura-role": hasuraRoleOverride || undefined,
  } as const satisfies Partial<HasuraHeaders>;

  // Remove null and undefined values from headers
  return pickBy(headers, (value) => value !== null && value !== undefined);
}
