import { useAuth, useUser } from "@clerk/nextjs";

export const useIsGreenlineStaff = () => {
  const { actor } = useAuth();
  const { user } = useUser();

  // If the user is being impersonated we know the current user is staff
  if (actor) {
    return true;
  }

  return user?.emailAddresses.some((email) =>
    email.emailAddress.endsWith("@greenline.ai")
  );
};
