import { jwtDecode } from "jwt-decode";

export type ClerkUserInvitationId = `inv_${string}`;

export type UserInvitationTokenClaims = {
  /**
   * Expiration time.
   * The time after which the token will expire, as a Unix timestamp.
   */
  exp: number;
  /** Clerk Instance ID */
  iid: `ins_${string}`;
  /** Redirect URL */
  rurl: string;
  /** Session ID - Invitation ID */
  sid: ClerkUserInvitationId;
  /** Session Type */
  st: "invitation";
};

export type ClerkOrganizationInvitationId = `orginv_${string}`;
export type ClerkOrganizationId = `org_${string}`;
export type ClerkSignInTokenId = `sit_${string}`;

export type OrganizationInvitationTokenClaims = {
  /**
   * Expiration time.
   * The time after which the token will expire, as a Unix timestamp.
   */
  exp: number;
  /** Clerk Organization ID */
  oid: ClerkOrganizationId;
  /** Clerk Instance ID */
  iid: `ins_${string}`;
  /** Redirect URL */
  rurl: string;
  /** Session ID - Invitation ID */
  sid: ClerkOrganizationInvitationId;
  /** Session Type */
  st: "organization_invitation";
};

export function decodeClerkInviteToken(
  ticket: string
): UserInvitationTokenClaims | OrganizationInvitationTokenClaims {
  return jwtDecode(ticket);
}

export type ClerkUserTokenClaims = {
  /**
   * Expiration time.
   * The time after which the token will expire, as a Unix timestamp.
   */
  exp: number;
  /** Clerk Instance ID */
  iid: `ins_${string}`;
  /** Session ID - Sign In Token ID */
  sid: ClerkSignInTokenId;
  /** Session Type */
  st: "sign_in_token";
};

/** Decode a sign in token created by an API call to Clerk for a user. */
export function decodeClerkSignInToken(
  ticket: string
): ClerkUserTokenClaims | undefined {
  const claims = jwtDecode(ticket);
  if (!("st" in claims) || claims.st !== "sign_in_token") {
    return undefined;
  }

  return claims as ClerkUserTokenClaims;
}
