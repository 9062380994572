"use client";
/**
 * @file Apollo clients for SSR (server side rendering).
 *
 * Since we use NextJS app routing, each client component will be SSR-rendered for the initial request.
 *
 * The RSC (React server components) client is separate from the SSR client because all queries
 * made in SSR can dynamically update in the browser as the cache updates (e.g. from a mutation or another query),
 * but queries made in RSC will not be updated in the browser - for that purpose, the full page would need to rerender.
 * As a result, any overlapping data would result in inconsistencies in your UI.
 */

import type { DefaultContext } from "@apollo/client";
import { ApolloLink, HttpLink, split, useApolloClient } from "@apollo/client";
import { loadDevMessages, loadErrorMessages } from "@apollo/client/dev";
import { setContext } from "@apollo/client/link/context";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { getMainDefinition } from "@apollo/client/utilities";
import {
  ApolloNextAppProvider,
  ApolloClient as NextSSRApolloClient,
  InMemoryCache as NextSSRInMemoryCache,
  SSRMultipartLink,
} from "@apollo/experimental-nextjs-app-support";
import { useAuth } from "@clerk/nextjs";
import type { GetToken } from "@clerk/types";
import type {
  HasuraBackendHeaders,
  HasuraHeaders,
} from "@greenline/hasura/bin/HasuraHeaders";
import type { HasuraUserRole } from "@greenline/hasura/bin/HasuraUserRole";
import { useSearchParams } from "next/navigation";

import { apolloCacheConfig } from "@/apollo/apollo-cache";
import { createRestartableWsClient } from "@/apollo/createRestartableWsClient";
import { errorLoggerLink } from "@/apollo/errorLoggerLink";
import { usePrevious } from "@/hooks/usePrevious";
import { decodeClerkInviteToken } from "@/helpers/clerk/jwt";

import { getHasuraEnvVars } from "./getHasuraEnvVars";
import { getHasuraHeaders } from "./getHasuraHeaders";

const { hasuraAPIUrl, hasuraSubscriptionUrl } = getHasuraEnvVars();

if (process.env.NODE_ENV !== "production") {
  loadErrorMessages();
  loadDevMessages();
}

declare module "@apollo/client" {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  export interface DefaultContext {
    headers?: Partial<HasuraHeaders | HasuraBackendHeaders>;
    getToken?: GetToken;
    userId?: string | null;
    orgId?: string | null;
    role?: HasuraUserRole;
    inviteId?: string | null;
  }
}

/**
 * Consumes an operation's context to set the request headers.
 * Does not override any headers that are already set.
 *
 * @example Overriding headers client-side
 * // This query is special in that it needs the x-hasura-invite-id to be set to the inviteId
 * const { data } = useQuery(MY_QUERY, {
 *   context: {
 *     headers: {
 *       "x-hasura-invite-id": inviteId,
 *     } satisfies Partial<HasuraHeaders>,
 *   }
 * });
 *
 * @example Overriding headers server-side
 * const { data } = await apolloClient.query({
 *   query: MY_QUERY,
 *   context: {
 *     // This mutation is special in that it needs the x-hasura-invite-id to be set to the inviteId
 *     headers: {
 *       "x-hasura-invite-id": inviteId,
 *     } satisfies HasuraBackendHeaders,
 *   }
 * });
 */
const authContextLink = setContext(
  async (_, context: DefaultContext): Promise<DefaultContext> => ({
    ...context,
    headers: {
      ...(await getHasuraHeaders(context)),
      // Do not override any headers that are already set.
      ...context.headers,
    },
  })
);

/**
 * This is only expected to be used to provide ApolloNextAppProvider with a client.
 */
function makeClient(): NextSSRApolloClient<unknown> & {
  /** function to re-connect the websocket for graphql subscriptions */
  restartWsClient: () => void;
} {
  const httpLink = new HttpLink({
    uri: hasuraAPIUrl,
  });

  // We store the latest auth data in the apolloClient.defaultContext object.
  // This function is used to access this data when creating a WebSocket connection.
  // eslint-disable-next-line prefer-const
  let getClientContext: () => Partial<DefaultContext>;
  const wsClient = createRestartableWsClient({
    url: hasuraSubscriptionUrl,
    /**
     * The connectionParams function is executed during the initial handshake of the WebSocket connection.
     * After the WebSocket connection is open, it is reused for all subsequent subscription requests as long as the connection remains active.
     * So, the headers or authentication token sent in the initial connection setup are used for all operations over that connection.
     * The function is only called again if the connection is closed and reopened.
     */
    connectionParams: async () => {
      const context = getClientContext() || {};
      return {
        headers: await getHasuraHeaders(context),
      };
    },
    lazy: true,
  });
  const wsLink = new GraphQLWsLink(wsClient);

  // Conditionally direct the request to the websocket link for subscriptions and the httpLink otherwise.
  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === "OperationDefinition" &&
        definition.operation === "subscription"
      );
    },
    // We don't add the authContextLink to the websocket link because, unlike the http Link,
    // the auth headers are only sent when establishing the WebSocket connection and not with each graphql operation.
    wsLink,
    // Ensure the authLink can inject the headers before the httpLink.
    // This is based on https://github.com/apollographql/apollo-client/issues/9893#issuecomment-1179929299
    // authContextLink will consume the context from the `<UpdateAuth>` component to set the request headers.
    // See https://www.apollographql.com/docs/react/api/link/apollo-link-context#overview
    authContextLink.concat(httpLink)
  );

  const apolloClient = new NextSSRApolloClient({
    cache: new NextSSRInMemoryCache(apolloCacheConfig),
    link: errorLoggerLink.concat(
      typeof window === "undefined"
        ? ApolloLink.from([
            // in a SSR environment, if you use multipart features like
            // @defer, you need to decide how to handle these.
            // This strips all interfaces with a `@defer` directive from your queries.
            new SSRMultipartLink({
              stripDefer: true,
            }),
            splitLink,
          ])
        : splitLink
    ),
  });

  // When the store is reset assume we also need to restart the WebSocket connection with new auth data.
  apolloClient.onClearStore(async () => {
    wsClient.restart();
  });
  apolloClient.onResetStore(async () => {
    wsClient.restart();
  });

  getClientContext = () => apolloClient.defaultContext;

  return Object.assign(apolloClient, {
    restartWsClient: () => wsClient.restart(),
  });
}

export type GreenlineApolloClient = ReturnType<typeof makeClient>;

/**
 * We are doing something stupid but necessary here.
 * We need the first child of ApolloNextAppProvider to be the UpdateAuth component so
 * it can access the client that was created and we can ensure apolloClient.defaultContext is
 * updated with the latest auth data.
 *
 * This pattern is borrowed from https://github.com/apollographql/apollo-client-nextjs/issues/103#issuecomment-1919516042
 */
function UpdateAuth({ children }: { children: React.ReactNode }) {
  // During SSR useAuth() wil provide immediate results when a child of the `<ClerkProvider>`
  const { orgRole, userId, orgId, getToken } = useAuth();

  const searchParams = useSearchParams();
  const clerkTicket = searchParams.get("__clerk_ticket");
  const maybeDecodedJwt = clerkTicket
    ? decodeClerkInviteToken(clerkTicket)
    : undefined;
  const inviteId = maybeDecodedJwt?.sid ?? searchParams.get("inviteId");

  const apolloClient = useApolloClient() as GreenlineApolloClient;
  // Synchronously update the `apolloClient.defaultContext` before any child component can be rendered
  // so the value is available for any query started in a child.
  apolloClient.defaultContext.getToken = getToken;
  apolloClient.defaultContext.userId = userId;
  apolloClient.defaultContext.orgId = orgId;
  apolloClient.defaultContext.orgRole = orgRole;
  apolloClient.defaultContext.inviteId = inviteId;

  // If the user changes their active org, reset the store.
  const previousOrgId = usePrevious(orgId ?? undefined);

  // If the user logs out, clear the store.
  const previousUserId = usePrevious(userId ?? undefined);
  if (previousUserId !== undefined && !userId) {
    // Unlike `resetStore`, `clearStore` will not refetch any active queries.
    apolloClient.clearStore().then(() => {
      // Ignore the promise
    });
  } else if (previousOrgId !== orgId) {
    // Restart the WebSocket connection so the latest connectionParams (including the new orgId) will be used
    apolloClient.restartWsClient();
  }

  return children;
}

export type ApolloProviderWrapperProps = {
  children?: React.ReactNode;
};

export function ApolloProviderWrapper({
  children,
}: ApolloProviderWrapperProps) {
  // 5/10/24:
  // The ApolloProvider does something stupid in that it doesn't call makeClient
  // again if the function reference changes. We have no way to tell
  // ApolloProvider "hey, my auth data changed. Please re-create the client." so we have to do some messy looking hacks.
  // Hopefully the next version of @apollo/experimental-nextjs-app-support will provide an easier way for us to do this...
  // Goal: Provide the most up-to-date user information in the request headers.
  return (
    <ApolloNextAppProvider makeClient={() => makeClient()}>
      <UpdateAuth>{children}</UpdateAuth>
    </ApolloNextAppProvider>
  );
}
